var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Calendar",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    staticClass: "Headline"
  }, [_c('h1', {
    staticClass: "Title"
  }, [_vm._v("Ihr Schichtplanner")])]), _c('div', {
    staticClass: "PlannerContainer"
  }, [_c('div', {
    staticClass: "MapSection"
  }, [!_vm.isFetchingData ? _c('div', {
    staticClass: "MapWrapper"
  }, [_c('l-map', {
    ref: "mapObject",
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center
    },
    on: {
      "ready": _vm.onMapReady
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.tileUrl,
      "attribution": _vm.attribution
    }
  }), _vm._l(_vm.employeeLocations, function (location, index) {
    return _c('l-marker', {
      key: index,
      ref: "marker",
      refInFor: true,
      attrs: {
        "lat-lng": [location.lat, location.lng]
      },
      on: {
        "click": function click($event) {
          return _vm.onMarkerClick(location);
        }
      }
    }, [_c('l-popup', [_vm._v(_vm._s(location.name))])], 1);
  })], 2), _c('div', {
    staticClass: "MapControls"
  }, [_c('Button', {
    attrs: {
      "size": "small",
      "variant": "minimal",
      "icon": "location"
    },
    on: {
      "onClick": _vm.centerMap
    }
  }, [_vm._v(" Karte zentrieren ")])], 1)], 1) : _c('div', {
    staticClass: "MapLoading"
  }, [_c('Spinner')], 1)]), _c('div', {
    staticClass: "ScheduleSection"
  }, [_c('AssigneeList', {
    attrs: {
      "assignees": _vm.employeeLocations,
      "selectedAssignee": _vm.selectedAssignee
    },
    on: {
      "assignee-click": _vm.onAssigneeClick
    }
  }), _c('div', {
    staticClass: "SchedulerActions"
  }, [_c('Button', {
    attrs: {
      "size": "small",
      "variant": "secondary"
    },
    on: {
      "onClick": _vm.handleSaveAsDefault
    }
  }, [_vm._v(" Als Standard speichern ")]), _c('Button', {
    attrs: {
      "size": "small",
      "variant": "secondary"
    },
    on: {
      "onClick": _vm.loadDefaultSchedule
    }
  }, [_vm._v(" Standard laden ")])], 1), _c('div', {
    staticClass: "SchedulerWrapper"
  }, [_c('Schedular', {
    attrs: {
      "events": _vm.filteredSchedule,
      "resources": _vm.currentCars,
      "assignees": _vm.currentEmployees,
      "variant": _vm.variant
    },
    on: {
      "onSaveAsDefault": _vm.handleSaveAsDefault,
      "onLoadDefault": _vm.loadDefaultSchedule,
      "onToggle": _vm.handleToggle,
      "onEventSave": _vm.handleEventSave,
      "onEventDelete": _vm.handleDeleteEvent
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }