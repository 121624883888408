<template>
    <div class="Calendar" :class="{ 'is-loading': isFetchingData }">
        <Headline class="Headline">
            <h1 class="Title">Ihr Schichtplanner</h1>
        </Headline>

        <div class="PlannerContainer">
            <!-- Map Section -->
            <div class="MapSection">
                <div v-if="!isFetchingData" class="MapWrapper">
                    <l-map ref="mapObject" :zoom="zoom" :center="center" @ready="onMapReady">
                        <l-tile-layer :url="tileUrl" :attribution="attribution"></l-tile-layer>
                        <l-marker
                            v-for="(location, index) in employeeLocations"
                            ref="marker"
                            :key="index"
                            :lat-lng="[location.lat, location.lng]"
                            @click="onMarkerClick(location)"
                        >
                            <l-popup>{{ location.name }}</l-popup>
                        </l-marker>
                    </l-map>
                    <div class="MapControls">
                        <Button @onClick="centerMap" size="small" variant="minimal" icon="location">
                            Karte zentrieren
                        </Button>
                    </div>
                </div>
                <div v-else class="MapLoading">
                    <Spinner />
                </div>
            </div>

            <!-- Employee List and Schedule Section -->
            <div class="ScheduleSection">
                <AssigneeList
                    :assignees="employeeLocations"
                    :selectedAssignee="selectedAssignee"
                    @assignee-click="onAssigneeClick"
                />

                <div class="SchedulerActions">
                    <Button @onClick="handleSaveAsDefault" size="small" variant="secondary">
                        Als Standard speichern
                    </Button>
                    <Button @onClick="loadDefaultSchedule" size="small" variant="secondary">
                        Standard laden
                    </Button>
                    <!-- <Button @onClick="handleToggle" size="small" variant="primary">
                        {{ variant === 'day-night' ? '24-Stunden Ansicht' : 'Tag/Nacht Ansicht' }}
                    </Button> -->
                </div>

                <div class="SchedulerWrapper">
                    <Schedular
                        :events="filteredSchedule"
                        :resources="currentCars"
                        :assignees="currentEmployees"
                        :variant="variant"
                        @onSaveAsDefault="handleSaveAsDefault"
                        @onLoadDefault="loadDefaultSchedule"
                        @onToggle="handleToggle"
                        @onEventSave="handleEventSave"
                        @onEventDelete="handleDeleteEvent"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

// Fix Leaflet icon issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';

// Import storage utils
import {
    loadDriverScheduleFromLocalStorage,
    saveDriverScheduleToLocalStorage,
    deleteDriverScheduleEventFromLocalStorage,
    saveDefaultScheduleToLocalStorage,
    loadDefaultScheduleFromLocalStorage,
} from '@/lib/localStorage';

// Component imports
import AssigneeList from '@/components/Schedular/components/AssigneeList.vue';
import Headline from '@/components/Headline';
import Button from '@/components/widgets/Button';
import Schedular from '@/components/Schedular';
import Spinner from '@/components/widgets/Spinner';

export default {
    name: 'SchedularView',
    components: {
        Headline,
        Button,
        Schedular,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        AssigneeList,
        Spinner,
    },

    data() {
        return {
            driverSchedule: [],
            defaultPattern: [],
            isFetchingData: true,
            variant: 'day-night',
            currentCars: [],

            // Map related data
            center: [51.1657, 10.4515],
            zoom: 6,
            tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            employeeLocations: [],
            selectedAssignee: null,
            mapIsReady: false,
        };
    },

    computed: {
        ...mapGetters({
            employees: 'employees',
            cars: 'cars',
        }),

        currentEmployees() {
            return this.employees
                .filter(e => e.isVisible && !e.isSystemDriver)
                .map(e => ({
                    ...e,
                    id: e.uuid,
                    value: e.name,
                }))
                .sort((a, b) => a.value.localeCompare(b.value));
        },

        filteredSchedule() {
            if (!this.selectedAssignee) return this.driverSchedule;

            return this.driverSchedule.filter(
                event =>
                    event.title === this.selectedAssignee.name ||
                    event.assigneeId === this.selectedAssignee.uuid,
            );
        },
    },

    methods: {
        ...mapActions(['getEmployees', 'getCars']),
        format,

        // Map interaction methods
        onMapReady() {
            this.mapIsReady = true;
            this.centerMap();
        },

        onAssigneeClick(assignee) {
            if (this.selectedAssignee && this.selectedAssignee.uuid === assignee.uuid) {
                this.selectedAssignee = null;
                return;
            }

            this.selectedAssignee = assignee;

            if (assignee.lat && assignee.lng && this.mapIsReady) {
                this.center = [assignee.lat, assignee.lng];
                this.zoom = 13;

                this.$nextTick(() => {
                    const map = this.$refs.mapObject?.mapObject;
                    if (!map) return;

                    map.setView(this.center, 13);

                    // Find and open the popup for this marker
                    const markers = this.$refs.marker;
                    if (!Array.isArray(markers)) return;

                    const marker = markers.find(m => {
                        if (!m?.$props?.latLng) return false;
                        return (
                            m.$props.latLng[0] === assignee.lat &&
                            m.$props.latLng[1] === assignee.lng
                        );
                    });

                    if (marker?.mapObject) {
                        marker.mapObject.openPopup();
                    }
                });
            }
        },

        onMarkerClick(assignee) {
            this.selectedAssignee = assignee;
            this.scrollToAssignee(assignee);
        },

        scrollToAssignee(assignee) {
            this.$nextTick(() => {
                const element = document.querySelector(`.AssigneeItem[data-id="${assignee.id}"]`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },

        centerMap() {
            if (!this.mapIsReady) return;

            if (this.employeeLocations.length > 0) {
                const bounds = L.latLngBounds(this.employeeLocations.map(l => [l.lat, l.lng]));
                this.center = bounds.getCenter();
                this.zoom = this.getBestZoomLevel(bounds);

                this.$nextTick(() => {
                    const map = this.$refs.mapObject?.mapObject;
                    if (map) {
                        map.fitBounds(bounds, { padding: [30, 30] });
                    }
                });
            }
        },

        getBestZoomLevel(bounds) {
            const size = bounds.getSize();
            return Math.min(9, size > 200 ? 6 : size > 100 ? 7 : 8);
        },

        // Data fetching methods
        async fetchEmployeeLocations() {
            // Try to get cached data first
            const storedLocations = JSON.parse(localStorage.getItem('employeeLocations'));
            const cacheExpiry = localStorage.getItem('employeeLocationsExpiry');
            const now = new Date().getTime();

            // Check if cache is valid (less than 24 hours old)
            if (storedLocations && cacheExpiry && now < parseInt(cacheExpiry)) {
                this.employeeLocations = storedLocations;
                return;
            }

            // Otherwise fetch fresh data
            const locations = [];

            for (const employee of this.employees) {
                if (employee.streetOne && employee.zipCode) {
                    try {
                        const coords = await this.geocodeAddress(
                            employee.streetOne,
                            employee.zipCode,
                        );

                        if (coords) {
                            locations.push({
                                name: employee.name,
                                ...employee,
                                ...coords,
                            });
                        }
                    } catch (error) {
                        console.error(`Failed to geocode for employee ${employee.name}:`, error);
                    }
                }
            }

            this.employeeLocations = locations;

            // Store in local storage with 24-hour expiry
            localStorage.setItem('employeeLocations', JSON.stringify(locations));
            localStorage.setItem('employeeLocationsExpiry', (now + 86400000).toString()); // 24h in ms
        },

        async geocodeAddress(streetOne, zipCode) {
            try {
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?street=${encodeURIComponent(
                        streetOne,
                    )}&postalcode=${encodeURIComponent(
                        zipCode,
                    )}&country=germany&format=json&limit=1`,
                    {
                        headers: {
                            'User-Agent': 'SchichtplannerApp/1.0', // Nominatim requires a user agent
                        },
                    },
                );

                if (response?.data?.length > 0) {
                    const { lat, lon } = response.data[0];
                    return { lat: parseFloat(lat), lng: parseFloat(lon) };
                }
            } catch (error) {
                console.error('Error fetching geocode data:', error);
            }

            return null;
        },

        // Schedule manipulation methods
        handleToggle() {
            this.variant = this.variant === 'day-night' ? '24h' : 'day-night';
        },

        handleEventSave(event) {
            let schedule = [...this.driverSchedule];
            const index = schedule.findIndex(e => e.id === event.id);

            if (index === -1) {
                // New event
                const newEvent = {
                    id: uuidv4(),
                    ...event,
                    assigneeId: this.selectedAssignee?.uuid || null,
                };

                schedule.unshift(newEvent);
            } else {
                // Update existing event
                schedule.splice(index, 1, {
                    ...event,
                    assigneeId: event.assigneeId || this.selectedAssignee?.uuid || null,
                });
            }

            saveDriverScheduleToLocalStorage(schedule);
            this.driverSchedule = schedule;
        },

        handleSaveAsDefault() {
            saveDefaultScheduleToLocalStorage(this.driverSchedule);
            this.$toast.success('Der aktuelle Schichtplan wurde als Standard gespeichert.');
        },

        loadDefaultSchedule() {
            const defaultSchedule = loadDefaultScheduleFromLocalStorage();
            if (defaultSchedule.length > 0) {
                this.driverSchedule = defaultSchedule;
                this.$toast.success('Standard-Schichtplan wurde geladen.');
            } else {
                this.$toast.error('Es wurde kein Standard-Schichtplan gefunden.');
            }
        },

        handleDeleteEvent({ id }) {
            this.$dialog.confirm({
                title: 'Termin löschen',
                message: 'Wollen Sie diesen Termin wirklich löschen?',
                confirmText: 'Ja, löschen',
                cancelText: 'Abbrechen',
                type: 'danger',
                onConfirm: () => {
                    const updatedSchedule = deleteDriverScheduleEventFromLocalStorage(id);
                    this.driverSchedule = updatedSchedule;
                    this.$toast.success('Termin wurde gelöscht.');
                },
            });
        },
    },

    async mounted() {
        try {
            this.isFetchingData = true;

            // Load data in parallel
            await Promise.all([this.getEmployees(), this.getCars()]);

            // Process cars data
            if (this.cars?.length) {
                this.currentCars = [...this.cars]
                    .filter(car => car.currentInstallation && car.isVisible)
                    .map(car => ({
                        ...car,
                        id: car.uuid,
                        value: car.currentInstallation.licenseNumber,
                        description: car.currentInstallation.driver,
                    }));
            }

            // Load driver schedule
            this.driverSchedule = loadDriverScheduleFromLocalStorage();

            // Fetch employee locations
            await this.fetchEmployeeLocations();
        } catch (error) {
            console.error('Error initializing Scheduler view:', error);
            this.$toast.error('Fehler beim Laden der Daten');
        } finally {
            this.isFetchingData = false;
            this.$emit('onFinishLoading');
        }
    },
};
</script>

<style lang="scss">
.Calendar {
    @extend %contentWrapper;
    @extend %page;

    .Headline {
        margin-bottom: 20px;

        .Title {
            margin-bottom: 0;
            font-size: 24px;
            color: var(--color-text-primary);
        }
    }

    .PlannerContainer {
        gap: 20px;

        @media (min-width: 768px) {
            grid-template-columns: 1fr;
        }

        @media (min-width: 1200px) {
            grid-template-columns: minmax(300px, 1fr) 2fr;
        }
    }

    .MapSection {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        height: 300px;
        margin-bottom: 20px;
    }

    .MapWrapper {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;

        .leaflet-container {
            height: 100%;
            width: 100%;
            z-index: 1;
            border-radius: 12px;
        }
    }

    .MapControls {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 500;
        display: flex;
        gap: 8px;

        button {
            background: white;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }

    .MapLoading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: var(--color-background-light);
    }

    .ScheduleSection {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .SchedulerActions {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 10px 0;
        justify-content: flex-end;

        @media (max-width: 768px) {
            justify-content: center;
        }
    }

    .SchedulerWrapper {
        background: white;
        border-radius: 12px;
        padding: 16px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        flex-grow: 1;
        overflow: hidden;
    }
}
</style>
