// Constants for storage keys
const STORAGE_KEYS = {
    SCHEDULER_EVENTS: 'schedulerEvents',
    DRIVER_SCHEDULE: 'driverSchedule',
    DEFAULT_DRIVER_SCHEDULE: 'defaultDriverSchedule',
    EMPLOYEE_LOCATIONS: 'employeeLocations',
    EMPLOYEE_LOCATIONS_EXPIRY: 'employeeLocationsExpiry',
};

// Cache expiration time (24 hours in milliseconds)
const CACHE_EXPIRY = 24 * 60 * 60 * 1000;

/**
 * General-purpose utility for saving data to localStorage with error handling
 * @param {string} key - The storage key
 * @param {any} data - The data to store
 * @returns {boolean} - Success status
 */
function saveToStorage(key, data) {
    try {
        localStorage.setItem(key, JSON.stringify(data));
        return true;
    } catch (error) {
        console.error(`Error saving to localStorage (${key}):`, error);
        return false;
    }
}

/**
 * General-purpose utility for loading data from localStorage
 * @param {string} key - The storage key
 * @param {any} defaultValue - Default value if key doesn't exist
 * @returns {any} - Parsed data or default value
 */
function loadFromStorage(key, defaultValue = []) {
    try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : defaultValue;
    } catch (error) {
        console.error(`Error loading from localStorage (${key}):`, error);
        return defaultValue;
    }
}

// Events Management

/**
 * Save scheduler events to local storage
 * @param {Array} events - Array of event objects
 * @returns {boolean} - Success status
 */
export function saveEventsToLocalStorage(events) {
    return saveToStorage(STORAGE_KEYS.SCHEDULER_EVENTS, events);
}

/**
 * Load scheduler events from local storage
 * @returns {Array} - Array of event objects
 */
export function loadEventsFromLocalStorage() {
    return loadFromStorage(STORAGE_KEYS.SCHEDULER_EVENTS);
}

/**
 * Delete an event from local storage and return updated events
 * @param {string} eventId - ID of the event to delete
 * @returns {Array} - Updated array of events
 */
export function deleteEventFromLocalStorage(eventId) {
    const events = loadEventsFromLocalStorage();
    const updatedEvents = events.filter(event => event.id !== eventId);
    saveEventsToLocalStorage(updatedEvents);
    return updatedEvents;
}

// Driver Schedule Management

/**
 * Save driver schedule to local storage
 * @param {Array} driverSchedule - Array of schedule entries
 * @returns {boolean} - Success status
 */
export function saveDriverScheduleToLocalStorage(driverSchedule) {
    return saveToStorage(STORAGE_KEYS.DRIVER_SCHEDULE, driverSchedule);
}

/**
 * Load driver schedule from local storage
 * @returns {Array} - Array of schedule entries
 */
export function loadDriverScheduleFromLocalStorage() {
    return loadFromStorage(STORAGE_KEYS.DRIVER_SCHEDULE);
}

/**
 * Delete a schedule event from local storage and return updated schedule
 * @param {string} eventId - ID of the event to delete
 * @returns {Array} - Updated array of schedule entries
 */
export function deleteDriverScheduleEventFromLocalStorage(eventId) {
    const driverSchedule = loadDriverScheduleFromLocalStorage();
    const updatedSchedule = driverSchedule.filter(event => event.id !== eventId);
    saveDriverScheduleToLocalStorage(updatedSchedule);
    return updatedSchedule;
}

/**
 * Save default schedule pattern to local storage
 * @param {Array} driverSchedule - Array of schedule entries
 * @returns {boolean} - Success status
 */
export function saveDefaultScheduleToLocalStorage(driverSchedule) {
    return saveToStorage(STORAGE_KEYS.DEFAULT_DRIVER_SCHEDULE, driverSchedule);
}

/**
 * Load default schedule pattern from local storage
 * @returns {Array} - Array of schedule entries
 */
export function loadDefaultScheduleFromLocalStorage() {
    return loadFromStorage(STORAGE_KEYS.DEFAULT_DRIVER_SCHEDULE);
}

// Employee Location Management

/**
 * Save employee locations to local storage with expiry time
 * @param {Array} locations - Array of employee location objects
 * @returns {boolean} - Success status
 */
export function saveEmployeeLocations(locations) {
    const expiryTime = Date.now() + CACHE_EXPIRY;
    saveToStorage(STORAGE_KEYS.EMPLOYEE_LOCATIONS_EXPIRY, expiryTime);
    return saveToStorage(STORAGE_KEYS.EMPLOYEE_LOCATIONS, locations);
}

/**
 * Load employee locations from local storage
 * @returns {Object} - Object containing locations and whether they're valid
 */
export function loadEmployeeLocations() {
    const locations = loadFromStorage(STORAGE_KEYS.EMPLOYEE_LOCATIONS);
    const expiryTime = Number(localStorage.getItem(STORAGE_KEYS.EMPLOYEE_LOCATIONS_EXPIRY) || 0);
    const isValid = Date.now() < expiryTime;

    return {
        locations,
        isValid,
    };
}

/**
 * Clear all scheduler-related data from local storage
 * Useful for troubleshooting or resetting the application
 */
export function clearAllSchedulerData() {
    Object.values(STORAGE_KEYS).forEach(key => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(`Error removing localStorage key (${key}):`, error);
        }
    });
}

/**
 * Get the size of all scheduler data in local storage (in bytes)
 * @returns {number} - Size in bytes
 */
export function getSchedulerDataSize() {
    let totalSize = 0;

    Object.values(STORAGE_KEYS).forEach(key => {
        const item = localStorage.getItem(key);
        if (item) {
            totalSize += item.length * 2; // Each character is 2 bytes in JS
        }
    });

    return totalSize;
}
